.vinyl {
  width: 25%;
  padding: 24px;
  transition: all 300ms ease;
}

.vinylImageWrapper {
  margin-bottom: 8px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  box-shadow: -2px 4px 6px 1px rgba(0, 0, 0, 0.15);
  transition: all 300ms ease;
}

.vinylImageWrapper:hover {
  transform: scale(1.02);
  transform: translateY(-4px);
  box-shadow: -4px 4px 10px 1px rgba(0, 0, 0, 0.25);
}

.vinylTitle {
  font-size: 20px;
  margin-bottom: 8px;
}

.titleLink {
  color: #242424;
}

.skeletonImgWrapper {
  margin-bottom: 10px;
  position: relative;
  border-radius: 12px;
}

.skeletonImg {
  max-height: 320px;
  max-width: 250px;
  transition: all 300ms ease;
  border-radius: 4px;
}

.skeletonTitle,
.skeletonRating,
.skeletonPrice {
  background-color: rgb(227, 227, 227) !important;
  margin-bottom: 8px;
}

.skeletonTitle {
  height: 21px;
  width: 100%;
}

.skeletonRating {
  width: 100px;
  height: 19px;
}

.skeletonPrice {
  width: 200px;
  height: 19px;
}

@media (max-width: 768px) {
  .vinyl {
    width: 50%;
  }
}
