.container {
  padding: 24px 0;
  background-color: #f8f9fa;
}

.row {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 24px;
}

.selectedTop {
  padding: 0 24px;
  margin-bottom: 36px;
  font-size: 32px;
  color: #242424;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.selectedTop > img {
  width: 30%;
  margin-bottom: 48px;
}

.title {
  margin-bottom: 16px;
  font-size: 40px;
}

.subTitle {
  margin-bottom: 16px;
  font-size: 30px;
  color: #0652b4;
  text-align: center;
}

.itemsHeader {
  background-color: #0652b4;
  margin: 0 24px;
  padding: 8px 16px;
  color: white;
  display: flex;
}

.detailsHeader {
  background-color: #0652b4;
  margin: 0 24px;
  padding: 8px 16px;
  color: white;
  display: flex;
  justify-content: center;
}

.headerVinyl {
  width: 60%;
  display: flex;
}

.headerDetails {
  width: 100%;
}

.headerTotal {
  width: 100%;
  max-width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 18px;
}

.headerQuantity {
  width: 40%;
  display: flex;
  align-items: center;
}

.orderItem {
  margin: 24px;
  display: flex;
  padding-right: 8px;
}

.itemImageWrapper {
  width: 60%;
  display: flex;
}

.itemImage {
  width: 100%;
  max-width: 90px;
  max-height: 128px;
  border-radius: 4px;
  box-shadow: -1px 3px 5px 2px rgba(0, 0, 0, 0.08);
}

.itemInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 8px 16px;
}

.itemTitle {
  font-size: 20px;
}

.itemPrice {
  margin-bottom: 16px;
}

.itemRemove {
  background-color: transparent;
  color: #cf0000;
  border: none;
  padding-left: 0;
  width: 48px;
  transition: all 200ms ease;
}

.itemRemove:hover {
  opacity: 0.6;
}

.itemQuantity {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* padding: 0 24px; */
}

.itemQuantity > span {
  width: 48px;
  display: flex;
  justify-content: center;
}

.itemQuantityNumber {
  padding: 8px;
  width: 60px;
}

.itemTotal {
  width: 100%;
  max-width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 18px;
}

.receiptCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 36px 48px;
  font-size: 20px;
}

.receiptCard > div {
  width: 50%;
}

.totalItem {
  font-size: 18px;
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.total {
  /* padding: 0 120px; */
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  min-width: 230px;
  margin-top: 32px;
}

.subTotal {
  border-top: 2px solid #0652b4;
  padding-top: 24px;
}

.totalPrice {
  font-weight: bold;
}

.shippngTitle {
  font-weight: 600;
}

.button {
  background-color: #0652b4;
  color: white;
  padding: 12px 24px;
  font-size: 20px;
  border-radius: 4px;
  border: none;
  transition: all 300ms ease;
  width: 40%;
  font-size: 16px;
  padding: 12px 0;
}

.button:active {
  transform: translateY(1px);
}

.button:hover {
  transform: scale(1.025);
}

@media (max-width: 775px) {
  .receiptCard {
    padding: 32px 24px;
  }

  .receiptCard > div > p {
    font-size: 16px;
    /* text-align: center; */
  }

  .itemTitle {
    font-size: 16px;
  }

  .selectedTop {
    margin-bottom: 36px;
  }

  .selectedTop > h2,
  .selectedTop > h3 {
    font-size: 28px;
  }

  .itemTitle {
    font-weight: bold;
  }
}

@media (max-width: 570px) {
  .receiptCard {
    flex-direction: column;
  }

  .receiptCard > div {
    margin: 16px 0;
    width: 100%;
    text-align: center;
  }

  .receiptCard > div > p {
    font-size: 14px;
    text-align: center;
  }

  .itemInfo {
    padding-right: 0;
  }

  .itemQuantity {
    width: 10%;
  }

  .selectedTop > h2,
  .selectedTop > h3 {
    font-size: 18px;
  }

  .itemImageWrapper {
    width: 45%;
  }

  .itemImageWrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .selectedTop {
    margin-bottom: 24px;
  }

  .orderItem {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .itemInfo {
    padding: 8px 0 0 0;
    text-align: center;
    justify-content: center;
  }

  .itemTotal {
    justify-content: center;
    text-align: center;
    font-size: 13px;
    margin-right: 4px;
  }

  .itemTitle {
    margin-bottom: 0;
    font-size: 18px;
  }

  .itemPrice {
    display: none;
  }

  .itemImage {
    max-width: 128px;
  }

  .headerQuantity,
  .headerTotal {
    display: none;
  }
  .itemsHeader {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .itemsHeader > div {
    text-align: center;
    width: auto;
  }

  .headerDetails {
    text-align: center;
  }

  .selectedTop > img {
    width: 65%;
  }

  .receiptCard > div > p {
    font-size: 18px;
  }
}

@media (max-width: 425px) {
  .receiptCard > div > p {
    font-size: 16px;
  }

  .orderItem > div,
  .itemTitle {
    font-size: 14px;
  }
}
