.salePrice {
  font-size: 16px;
  margin: 8px 0 24px 0;
}

.normalPrice {
  text-decoration: line-through;
  padding-right: 6px;
  color: #bfbfbf;
}

.noClick {
  cursor: not-allowed;
}
