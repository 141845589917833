html {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45%;
  max-width: 500px;
  z-index: 1001;
  overflow: hidden;
}

.header {
  background: #0652b4;
  height: 10vh;
  padding: 12px 0 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  margin: 0;
  color: #fff;
}

.actions {
  padding-bottom: 1.5rem;
  padding-top: 8px;
  display: flex;
  justify-content: center;
}

.card {
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
}

.text {
  text-align: center;
  font-weight: bold;
}

.button {
  font: inherit;
  border: 1px solid #0652b4;
  background: #0652b4;
  border-radius: 16px;
  color: white;
  padding: 1rem 0.5rem;
  cursor: pointer;
  transition: all 450ms ease;
  width: 40%;
  font-weight: bolder;
  font-size: 18px;
}

.button:hover,
.button:active {
  background: #0652b4;
  border-color: #0652b4;
}

.button:hover {
  background-color: #fff;
  border: 2px solid black;
  transform: scale(1.015);
  color: black;
}

.button:focus {
  outline: none;
}

.exit {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin: 12px 20px 0 0;
  cursor: pointer;
  font-size: 28px;
}

.exitIcon {
  color: #fff;
  transition: all 300ms ease;
}

.exitIcon:hover {
  color: #ba181b;
  transform: scale(1.1);
}

.content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width: 768px) {
  .modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
  }
}

@media (max-width: 375px) {
  .modal {
    width: 95%;
  }
}
