.container {
  padding: 56px 0;
}

.row {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  display: flex;
}

.logo:hover .logoImg {
  transform: scale(1.1);
  filter: none;
}

.logoImg {
  width: 160px;
  filter: grayscale(1) invert(1);
  transition: all 300ms ease;
}

.footerList {
  margin: 20px 0 24px 0;
  display: flex;
  justify-content: space-evenly;
}

.footerLink {
  padding: 0 32px;
  transition: all 300ms ease;
}

.footerLink:hover {
  text-decoration: none;
  color: #0652b4;
  font-weight: bold;
  font-size: 17px;
}

.noCursor {
  cursor: not-allowed;
}

.footerLink,
.copyright {
  color: #fff;
  text-align: center;
}

@media (max-width: 550px) {
  .footerList {
    width: 80%;
    flex-direction: column;
  }

  .footerLink {
    padding: 8px;
  }
}
