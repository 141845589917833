.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 317.3px);
}

.container > h1 {
  margin-top: 64px;
  font-size: 68px;
  transition: all 400ms ease;
  margin-bottom: 16px;
}

.container > h1:hover {
  color: black;
}

.container > hr {
  border: 1px solid #000;
  width: 20%;
}

.ordersContainer {
  width: 70%;
}

@media (max-width: 635px) {
  .ordersContainer {
    width: 90%;
  }

  .container > h1 {
    margin-top: 16px;
    padding: 0 32px;
  }
}
