@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 80px;
}

html {
  scroll-behavior: smooth;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

nav {
  position: fixed;
  top: 0;
  height: 80px;
  display: flex;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.05);
  background-color: white;
  width: 100%;
  z-index: 999;
}

img {
  width: 100%;
  height: 100%;
  max-width: 50vh;
}

p {
  line-height: 1.5;
}

a {
  text-decoration: none;
}

ul {
  display: flex;
}

li {
  list-style-type: none;
}

h1 {
  font-size: 56px;
  color: #0652b4;
  margin-bottom: 24px;
  text-align: center;
}

h2 {
  font-size: 32px;
  margin-bottom: 32px;
  text-align: center;
}

button {
  cursor: pointer;
}

button:active {
  transform: translateY(1px);
}

.menu__backdrop {
  position: absolute;
  background-color: #242424;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  transition: all 400ms ease;
  opacity: 0;
  transform: translate(100%);
}

.menu--open {
  max-height: 100vh;
  overflow: hidden;
}

.menu--open .menu__backdrop {
  visibility: visible;
  opacity: 1;
  transform: translate(0);
}

/* 

LANDING

*/

#landing {
  background-color: rgb(74 132 255 / 19%);
}

header {
  height: calc(100vh + 80px);
}

/* 

HIGHLIGHTS 

*/

#highlights {
  position: relative;
  max-width: 100vw;
}

#highlights:after,
#highlights:before {
  background: url("./assets/wave.svg");
  content: "";
  position: absolute;
  width: 101vw;
  height: 12vw;
  left: 0;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
}

#highlights:before {
  transform: translateY(-100%);
  top: 1px;
}

#highlights:after {
  transform: translateY(100%) rotate(180deg);
  bottom: 1px;
}

#features {
  padding-top: 8vw;
  background-color: rgb(74 132 255 / 19%);
}

/* 

EXPLORE

*/

#explore {
  background-color: rgb(74 132 255 / 19%);
}

/* 

FOOTER

*/

footer {
  background-color: #242424;
}

/* 
**

VINYLS PAGE

**
*/

#vinyls__body {
  background-color: rgba(115, 66, 214, 0.1);
  flex: 1;
  display: flex;
  flex-direction: column;
}

#vinyls__main {
  flex: 1;
  background-color: rgb(248, 249, 250);
}

/* SMALL PHONES, TABLETS, LARGE SMARTPHONES */
@media (max-width: 768px) {
  h1 {
    font-size: 40px;
    padding: 32px 0;
    margin-top: 24px;
  }

  h2 {
    font-size: 26px;
  }

  button {
    font-size: 18px;
  }
}

/* ID */

#recent {
  padding-top: 40px;
}

.row h2 {
  padding-bottom: 18px;
}

#vinyl__selected--title {
  margin-bottom: 0;
  padding-bottom: 0;
}

.text__center {
  text-align: center;
}
