.container {
  padding: 56px 0;
}

.row {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blue {
  color: #0652b4;
}

.button {
  background-color: #0652b4;
  color: white;
  padding: 12px 24px;
  font-size: 20px;
  border-radius: 4px;
  border: none;
  transition: all 300ms ease;
}

.button:active {
  transform: translateY(1px);
}

.button:hover {
  transform: scale(1.025);
}
