.container {
  width: 100%;
  max-width: 1200px;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.logo {
  width: 160px;
}

.navList {
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navLink {
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  margin: 0 8px;
  color: #0652b4;
  position: relative;
  transition: color 0.3s ease-in-out;
}

.navLink::before {
  content: "";
  position: absolute;
  top: 100%;
  width: 100%;
  height: 3px;
  background-color: #0652b4;
  border-radius: 4px;
  transform: scaleX(0);
  transition: transform 400ms ease-in-out;
}

.navLink:hover {
  color: black;
}

.navLink:hover::before {
  transform: scaleX(1);
}

.navButton {
  background-color: transparent;
  border: none;
}

.menuButton {
  background-color: transparent;
  font-size: 32px;
  border: none;
  color: #0652b4;
  display: none;
}

.menuButtonClose {
  position: absolute;
  top: 10px;
  right: 20px;
  padding: 8px;
  color: white;
  font-size: 48px;
}

.menuButtonClose > svg {
  transition: all 300ms ease;
}

.menuButtonClose > svg:hover {
  color: red;
  transform: scale(1.1);
}

.navIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0652b4;
  font-size: 20px;
  position: relative;
  margin-left: 8px;
}

.navIconLink {
  transition: all 300ms ease;
}

.shoppingCart {
  transition: all 400ms ease;
  margin-left: 16px;
  color: #0652b4;
  text-decoration: none;
  font-size: 21px;
  font-weight: bold;
}

.navIconLink:hover .shoppingCart {
  transform: scale(1.2);
  color: black;
}

.cartQuantity {
  background-color: red;
  color: white;
  width: 18px;
  height: 18px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  bottom: 4px;
  right: -4px;
  pointer-events: none;
}

.menuLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menuLink {
  color: white;
  font-size: 40px;
  transition: all 300ms ease;
}

.menuLink:hover {
  color: #0652b4;
  font-size: 44px;
}

.menuList {
  padding: 32px 0;
}

.menuLinkButton {
  background-color: transparent;
  border: none;
}

@media (max-width: 650px) {
  .menuButton {
    display: block;
  }

  .navList {
    display: none;
  }
}
