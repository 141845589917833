.orderItem {
  display: flex;
  align-items: center;
  padding: 24px 0px;
}

.imgWrapper {
  max-width: 120px;
  max-height: 120px;
}

.imgWrapper > img {
  border-radius: 6px;
  box-shadow: 0 4px 5px -1px rgb(0 0 0 / 0.2), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.itemDescription {
  margin-left: 28px;
}

.itemDescription > h5 {
  font-size: 18px;
}

.itemDescription > h4 {
  font-size: 15px;
}

.itemDescription > p {
  font-size: 15px;
}

.quantity {
  line-height: 1;
  margin-bottom: 8px;
}

.itemDescription > button {
  background-color: #0652b4;
  border: 1px solid #0652b4;
  color: white;
  padding: 8px 8px;
  font-size: 14px !important;
  border-radius: 4px;
  border: none;
  transition: all 300ms ease;
  /* margin-top: 20px; */
}

.itemDescription > button:active {
  transform: translateY(1px);
}

.itemDescription > button:hover {
  transform: scale(1.025);
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.15), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  background-color: #fff;
  color: #0652b4;
  border-color: #d5d9d9;
}

@media(max-width: 1200px) {
  .orderItem {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 24px 0;
    padding: 24px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    transition: all 300ms ease;
  }

  .orderItem:hover {
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.2), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  }

  .itemDescription {
    margin: auto;
    margin-top: 8px;
  }

  .itemDescription > button {
    margin-top: 4px;
  }
}
