.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 30vh;
  left: 10%;
  width: 80%;
  z-index: 1001;
  overflow: hidden;
}

.header {
  background: rgb(172, 0, 0);
  height: auto;
  padding: 1rem;
}

.header h2 {
  margin: 0;
  color: white;
}

.content {
  padding: 3rem 2rem;
  text-align: center;
}

.content > p {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.actions {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.card {
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
}

.button {
  font: inherit;
  border: 1px solid #0652b4;
  background: #0652b4;
  border-radius: 4px;
  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 450ms ease;
  margin: 0 14px;
  font-weight: bold;
}

.cancelButton {
  border: 1px solid rgb(172, 0, 0);
  background: rgb(172, 0, 0);
}

.button:hover,
.button:active {
  background: #0652b4;
  border-color: #0652b4;
}

.button:hover {
  background-color: #fff;
  border-color: #fff;
  color: #0652b4;
  transform: scale(1.015);
}

.cancelButton:hover {
  border: 1px solid rgb(172, 0, 0);
  color: rgb(172, 0, 0);
  font-weight: bold;
  background: #fff;
}

.button:focus {
  outline: none;
}

.spinner {
  animation: loading 600ms infinite linear;
  transition: all 300ms ease;
  font-size: 26px;
}

.exit {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin: 12px 24px 0 0;
  cursor: pointer;
  font-size: 28px;
}

.exitIcon {
  color: #fff;
  transition: all 300ms ease;
}

.exitIcon:hover {
  color: red;
  transform: scale(1.1);
}

.red {
  color: rgb(233, 1, 1);
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 36rem;
  }
}

@media (max-width: 450px) {
  header {
    padding: 1rem 44px !important;
  }
  .actions {
    justify-content: space-evenly;
  }
}
