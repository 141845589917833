.container {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.description {
  display: flex;
  padding: 75px 0 50px 0px;
  flex-direction: column;
  align-items: center;
  z-index: 100;
}

.span {
  color: #0652b4;
  text-align: center;
}

.button {
  background-color: #0652b4;
  color: white;
  padding: 12px 24px;
  font-size: 20px;
  border-radius: 4px;
  border: none;
  transition: all 300ms ease;
  box-shadow: -1px 2px 5px 2px rgba(0, 0, 0, 0.13);
}

.button:active {
  transform: translateY(1px);
}

.button:hover {
  transform: scale(1.025);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.2) 0px 8px 16px -8px;
}

.imgWrapper {
  padding: 40px 0 20px 0;
  margin-top: 24px;
  z-index: 50;
  width: 700px;
  align-self: center;
  display: flex;
  justify-content: center;
}
